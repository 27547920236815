import React from "react"
import PropTypes from "prop-types"

import Navibar from "../components/Navibar"
import FooterPost from "../components/FooterPost"

import "./LayoutPost.scss"

const LayoutPost = ({ children }) => {
  return (
    <div>
      <Navibar />
      <div className="bgLayout page-container">
        <div className="content-wrap">
          <main>{children}</main>
        </div>
        <FooterPost />
      </div>
    </div>
  )
}

LayoutPost.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutPost
