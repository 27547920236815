import React from "react"

import "./FooterPost.scss"

const FooterPost = () => {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          
          {/* Column2 */}
          {/* Column3 */}
        </div>
        <div className="row">
          <p className="col-sm">
              &copy;{new Date().getFullYear()} All rights reserved by Hao Min
          </p>
        </div>
      </div>
    </div>
  )
}

export default FooterPost
